.SchoolReportsHeader {
    width: 100%;
    display: flex;
    height: 32px;
    overflow: hidden;
    background: #247BB3;
    color: white;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    position: sticky;
    top: 0;
}

.SchoolReportsField {
    width: 100%;
    display: flex;
    height: 32px;
    overflow: hidden;
    background: #247BB3;
    color: white;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    background-color: white;
    color: #000000;
    margin-top: 3px;
}

.SchoolReportsHeader_no {
    padding-left: 1%;
    width: 11%;
   
    display: flex;
    align-items: center;
    justify-content: center;
}

.SchoolReportsHeader_name {
    width: calc(15% - 6px);
    padding-left: 16px;
}

.SchoolReportsHeader_Standard {
    width: 13%;
}

.SchoolReportsHeader_sex {
    width: 27%;
}

.SchoolReportsHeader_cat {
    width: 13%;
}

.SchoolReportsHeader_Visit {
    width: 12%;
}

.SchoolReportsHeader_admission {
    width: 15%;
    margin-left: 2%;
    display: flex;
    text-align: center;
    justify-content: center;

}

.SchoolReportsHeader_number {
    width: 17%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.SchoolReportsHeader_details {
    width: 13%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}