.SchoolInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
    width: 1200px;
}

.SchoolInfoTop {
    display: flex;
    width: calc(1200px - 130px);
    height: 300px;
    padding: 21px 100px 0 30px;
    justify-content: space-between;
}

.SchoolInfofir {
    display: flex;
    flex-direction: column;
    height: 100%;
    /* align-items:space-between; */
    justify-content: space-around;
}

.SchoolInfofirName {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    /* line-height: 26px; */
    color: #545454;
    width: 149px;
}

.SchoolInfofirtxt {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    /* line-height: 26px; */
    color: #545454;

}

.SchoolInfofirdiv {
    display: flex;
    padding: 6px 0;
    align-items: center;
}

.SchoolInfosec {
    display: flex;
    flex-direction: column;
}

.SchoolInfosec>h3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #545454;
}

.SchoolInfosec>li {
    margin: 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #545454;
}

.SchoolInfolast {
    display: flex;
    flex-direction: column;
    width: calc(1200px - 130px);
    /* height: 350px; */
    padding: 21px 100px 0 30px;
    justify-content: space-between;
}

.SchoolInfolast_fir {
    display: flex;
}

.SchoolInfolast_fir>p {
    width: 900px;
    height: 160px;
    margin: 0;
    overflow-y: scroll;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: .5px;
    color: #545454;
}

.SchoolInfolast_last_imgfolder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
}
.SchoolInfolast_last_imgfolder>img {
    width: 200px;
    height: 114px;
}

.Customize_txt {
    display: flex;
    cursor: pointer;
}

.Customize_txt>img {
    display: flex;
    width: 16px;
    object-fit: contain;
}

.Customize_img {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #247BB3;
    padding-left: 6px;
}

.Popup_info {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    min-width: 1200px;
    min-height: 500px;
    background-color: rgba(0, 0, 0, .32);
    ;
    left: 0;
    z-index: 1000;
}

.pop_info {
    width: 630px;
    height: 437px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 12px;
}

.pop_info_cross {
    display: flex;
    justify-content: flex-end;
}

.pop_info_img {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.pop_info_img>img {
    width: 40%;
    padding: 21px 0 21px 0;
}

.addImage_box {
    width: 40%;
    height: 140px;
    margin: 21px 0 21px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 2px dashed rgba(144, 144, 144, 0.7);
}
.addImage_box >span{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #247BB3;
    padding-top: 12px;
}