.smHead {
    display: flex;
    /* width: calc(95%); */
    padding-left: 60px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    color: #545454;
    height: 42px;
}
.smBody{
    display: flex;
    flex-direction: column;
    height: calc(100%-42px);
    margin-left: 60px;
    min-width: 1300px;
}

.manage_graph_changes {
    display: flex;
    width: 1290px;
    height: 335px;
    /* align-items: center; */
    justify-content: space-between;
    /* background-color: black; */
}

.header_for_manages {
    display: flex;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #545454;
    background-color: #f8f9ff !important;
    width: 100%;
    padding: 4px 0;
}