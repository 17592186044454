.Lead_Header {
    position: sticky;
    top: 0;
    display: flex;
    width: 1200px;
    /* height: 480px; */
    justify-content: space-between;
    background: #247BB2;
    padding: 12px;
    /* z-index: 200; */
    margin-top: 0px;
   
}

.Lead_Header_feild {
    display: flex;
    width: 1200px;
    justify-content: space-between;
    padding: 8px 12px;
    align-items: center;
    border-bottom: 0.5px solid rgba(144, 144, 144, 0.3);
    
}

.remarkcss{
    width: 125px;
  height: 31px;
  resize: none;
  font-size: 12px;
border-color: #c1c0c0;
border-radius: 2px;


}

.maindatory{
    display: flex;
    text-align: center;
    align-items: center;
}
.star{
    color: red;

}

.Lead_Header_feild>input {
    width: 33px;
    margin: 0;
    padding: 0;
}

.Lead_Header>div {
    font-family: roboto;
    /* font-family: 'Roboto';
    font-style: normal; */
   
    font-size: 15px;
    /* identical to box height */
      color: white !important;
    font-weight: 700;
}

.Lead_Header_feild>div {
   font-family: 'Roboto';
     /* font-style: normal; */
    font-weight: 600;
    font-size: 13px;
    line-height: 14px;
    /* color: black !important; */
    color: #474444;
}

.contact_spx:hover {
    cursor: pointer;
    text-decoration: underline
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: #c1c0c0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.Date {
    width: 82.5px;
    color: black;
}

.Time {
    width: 82.5px;
}

.TicketN {
    width: 150px;
}

.Name {
    width: 104px;
   color: black;
  
}

.xyz {
    width: 137px;
   color: black;
   display: flex;
   align-items: center;
   justify-content: center;
}

.NameHeader {
    width: 131px;
    margin-left: 51px;
}

.gender {
    width: 220px;
   
}
.calender{
    width: 20px;
    height: 31px;
    color: white !important;
    border: white !important;
}


.Contact {
    width: 99px;
    color: black;
}
.ContactHeader {
    width: 158px;
    margin-left: 45px;
}

.Email {
    width: 150px;
    color: black;
    word-break: break-word;
    padding-right: 15px;
}

.Email2 {
    width: 224px;
    color: black;
    margin-left: 31px;
}

.Approved {
    width: 260px;
    color: black;
    margin-left: 5px;
}

.Registration {
    width: 260px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Admission{
    width: 260px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.EmailHeader {
    width: 249px;
    margin-left: 12px;
}

.School_Name {
    width: 214.5px;
    /* display: flex; */
}
.SendToSchool{
    display: flex;
    width: 50px;
    font-size: 12px;
    align-items: center;
    justify-content: center;
}
/* .responseFromSchool{
    display: flex;
    width: 100px;
    font-size: 12px;
    align-items: center;
    justify-content: center;
} */
.school_box {
    /* margin: 0 0.5%; */
    position: relative;
    border: 1px solid rgba(144, 144, 144, 0.4);
    padding: 6px 0px;
    display: flex;
    justify-content: space-around;
}

.school_box>img {
    /* padding-left: 10px; */
    width: 10px;
    object-fit: contain;
}

.Category {
    width: 99px;
}

.Board {
    width: 101px;
    margin-left: 7px;
    margin-right: 34px;
    color: black;
}


.Board1 {
    width: 101px;
    margin-left: 19px;
    margin-right: 45px;
    color: black;
}

.Budget {
    width: 66px;
}

.Sex {
    width: 66px;
}

.Standard {
    width: 57.75px;
}

.status {
    width: 66px;
}

.statusNew {
    width: 70px;
    margin-left: 12px;
}

.assignto{
    width: 90.75px;
}

.leadOrigin{
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Remarks {
    width: 115.5px;
    /* margin-left: 16.5px; */
}
.morevert{
    position: relative;
    width: 33px;
}
.dropDown {
    /* color: wheat; */
    color: var(--logo-color) !important;
}

/* .Dropdown-control {
    padding: 8px 2px 8px 2px !important;
    transition: all 200ms ease;
} */

.Dropdown-placeholder {
    padding-right: 0;
    /* font-size: 12px !important; */
}

.Dropdown-arrow-wrapper {
    background: pink !important;
    color: #474444 !important;
    width: 0 !important;
}

.dropDown>p {
    display: flex;
    padding: 10px;
    margin: 0;
    transition: all .2s ease-in-out;
    cursor: pointer
}

.dropDown>p:hover {
    box-shadow: 0 0 4px rgb(204, 204, 204);
    border-radius: 2px 2px 0 0;
    background-color: #f8f8f8
}

.Dropdown-menu {
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgb(0 0 0 / 6%);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: scroll !important;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 100 !important;
    /* height: 100%; */
}

.PopupForLead {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .32);
    ;
    z-index: 1000;
}

.Popup_inForLead {
    display: flex;
    flex-direction: column;
    height: 59vh;
    width: 500px;
    background-color: white;
    box-shadow: 5px 5px 20px rgb(0 0 0 / 30%);
    border-radius: 20px;
    align-items: center;
    justify-content: center;
}

.PopFORdrop {
    position: absolute;
    bottom: -31px;
    z-index: 100;
    right:0;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 6px;
    width: 100px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #545454;
}
#PopFORdropTop{
    position: absolute;
    right: 0;
    width: fit-content;
    margin-right: 6px;
    display: flex;
    height: 13px;
    width: 13px;
    object-fit: contain;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    color: black;
    border: 1px solid black;
    cursor: pointer;
}
#PopFORdropImg{
    display: flex;
    height: 21px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 19px;
    border-bottom: 1px solid lightgray;
    border-top: 1px solid lightgray;
}
#PopFORdropImg>img {
    /* width: 12px; */
    padding-right: 4px;
    height: 17px;
    object-fit: contain;
}
.pop_body__forsend{
    height: 300px;
    overflow-y: scroll;
    /* width: 80%; */
    /* background-color: #474444; */
}
.FieldSendSchool {
    display: flex;
    padding: 8px 12px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.5px solid rgba(144, 144, 144, 0.3);
    font-size: 12px;
}
.pop_app_button{
    background-color: none;
    border-radius: 8px;
    padding:  6px 16px;
    border: none;
    outline: none;
    /* margin: 12px 12px 12px 0; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    background-color: #1a73e8;
    color: #fff;
}
*:disabled {
    background-color: rgb(245, 245, 245) !important;

    opacity: 1;
}
.Dropdown-disabled{
    background-color: rgb(245, 245, 245) !important;

}
/* FieldSendSchool */
/* .popup-arrow {
    display: none !important;
}

[role=tooltip].popup-content {
  max-width: 100px !important;
  box-shadow: 0 0 3px rgb(0 0 0 / 16%);
  border-radius: 5px;
  background: #fff;
  border: 1px solid lightblue;
} */
/* .popup-content {
  margin: auto;
  background: #fff;
  padding: 5px;
  border: 1px solid #d7d7d7;
} */