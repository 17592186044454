.Billing_name {
    display: flex;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    color: #545454;
    margin: 21px 0;
}

.school_package {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 12px;
    background: #FFE091;
    border: 2px solid #FCBE21;
    border-radius: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #FFB800;
    margin-left: 12px;
    height: 24px;
}

.package_name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #545454;
}

.pack_details {
    display: flex;
    height: 105px;
    padding-top: 16px;
}

.package_txt {
    display: flex;
    flex-direction: column;
    font-weight: 300 !important;
    font-size: 16px;
    line-height: 21px;
    width: 383px;
    height: 100%;
}

.school_package_type1 {
    background: #FCBE21;
    border-radius: 15px;
    color: white;
    border: none;
}

.school_package_type2 {
    background: #5FB51B;
    border-radius: 15px;
    color: white;
    border: none;
}

.school_package_type3 {
    background: #FC7272;
    border-radius: 15px;
    color: white;
    border: none;
}

.Billing_Table>span {
    display: flex;
    padding: 21px 0;
    justify-content: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #545454;
}
.RecentChange {
    display: flex;
    flex-direction: column;
    height: 270px;
    margin: 0;
    padding: 0;
}

.RecentChangeHeader {
    display: flex;
    height: 35px;
    overflow: hidden;
    background: #247BB3;
    color: white;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    width: 846px;
}

.RecentChangeHeader_Date {
    padding-left: 3%;
    width: 7%;
}

.RecentChangeHeader_name {
    width: 25%;

}

.RecentChangeHeader_changes {
    width: 25%;

}

.RecentChangeHeader_status {
    width: 15%;

}

.RecentChangeHeader_time {
    width: 25%;

}


.BillingBox{
    display: flex;
    flex-direction: row;
    
}

.imgSize{
    width: 300px;

}



.BillingTop{
display: flex;
    flex-direction: column;
    text-align: center;
}