.Signup {
    width: 100vw;
    min-width: 1300px;
    min-width: 1440px;
    height: 100vh;
    background: linear-gradient(180deg, #3D89BA 24.38%, #D3E3F1 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -2;
}

.signup_body {
    display: flex;
}

.signup_bodyDiv1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 400px;
    height: 500px;
    padding: 0 50px;
}

.signupImagesDiv {
    display: flex;
    height: 35%;
    margin-bottom: 4%;
    align-items: flex-end;
    justify-content: center;
}

.signupImagesDiv>img {
    margin: 3px;
    width: 50px;
    height: 50px;
}

.signup_bodyDiv2 {
    width: 500px;
    height: 500px;
    margin-left: -15px;
    margin-top: -6.4px;
    z-index: 1;
}

.signup_bodyDiv2_img1 {
    z-index: -1;
    position: absolute;
    /* height: 100%;
    width: 100%; */
}

.signUp_input {
    outline: none;
    padding: none;
    padding: 12px;
    margin: 6px;
    background: #F6F6F6;
    border: 1.5px solid rgba(144, 144, 144, 0.2);
    border-radius: 10px;
    width: 100%;
}

.checkdiv {
    width: 100%;
    align-items: center;
    justify-content: center;
    color: #247BB3;
    margin-bottom: 20px;
}

.checkdiv>span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #247BB3;
}

.signup_bodyDiv1>button {
    padding: 6px;
    outline: none;
    border: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    width: fit-content;
    padding: 8px 20px;
    margin: 6px;
    color: #FFFFFF;
    background: #247BB3;
    border-radius: 20px;
}

.forgot {
    margin-top: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    color: #247BB3;
    cursor: pointer;
}

.signup_bodyDiv2div {
    display: flex;
    flex-direction: column;
    padding: 15px;
    margin: 15% 0 0 10%;
}
.signup_bodyDiv2_img2 {
    display: flex;
    height: 117px;
    width: 120px;
    margin: 10% 0;
}


.signup2txt1 {
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    color: #FFFFFF;
    padding: 4px;
}

.signup2txt2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 35px;
    padding: 4px;
    cursor: pointer;
}
.signup2txt2>span{
    text-decoration-line: underline;
    color: #FFFFFF;
}