.loadingBackground{
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
   background-color: rgba(132, 196, 221, 0.364);
   z-index: 1000;
   font-family: 'Roboto';
}