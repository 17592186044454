@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

.home {
    /* width: 100vw !important; */
    height: 100vh !important;
    background: #F8F9FF;
  
}
.abc{
    color: red;
    margin-left: 80%;
    height: 0px;
}

.home_body {
    margin-left: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -12px;
    min-width: 1200px;
}

.home_body>h3 {
    display: flex;
    justify-content: flex-start;
    width: 90%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    color: #545454;
    padding: 0;
    margin: 0px;
}

.home_leads {
    display: flex;
    width: 90%;
    justify-content: space-between;
}

.home_leads_box {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 1200px;
    
}

.lead_box_Sec {
    /* overflow-x: ; */
    /* overflow-y: scroll; */
    height: 450px;
    width: 1200px;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px 4px rgba(0, 0, 0, 0.06);
    margin-top: 40px;
}

.lead_box_firstDiv {
    display: flex;
    
}

.margin{

margin-left:35px ;
margin-bottom: 20px;

margin-top: 30px;
padding: 15px 30px;
background-color: #0084D9;
color: #F8F9FF;
border-radius: 10px;
}

.margin:hover {
    background-color: indigo;
}

.btn{

 
    margin-bottom: 20px;
    margin-top: 30px;
    padding: 15px 50px;
    background-color: #0084D9;
    color: #F8F9FF;
    border-radius: 10px;
    }
    
    .btn:hover {
        background-color: indigo;

    }
 .active,.btn:hover {
        background-color: indigo;
        
    }
.home_leads_input {
    display: flex;
    width: 941.5px;
    align-items: center;
    justify-content: space-around;
}

.home_leads_inp_fir {
    width: 289px;
    display: flex;
    border: 1px solid rgba(144, 144, 144, 0.5);
    border-radius: 6px;
    padding: 8px 6px 8px 12px;
    margin-left: 12px;
    height: 17.2px;
    justify-content: space-around;
    align-items: center;
}
.home_leads_inp_fir >img{
    height: 15px;
    object-fit: contain;
}

.home_leads_inp_fir>input {
    border: none;
    outline: none;
    padding-left: 12px;
    width: 50%;
}
.home_leads_inp_fir>div {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: rgba(144, 144, 144, 0.5);
}

.home_leads_inp_sec {
    width: 107px;
    display: flex;
    border: 1px solid rgba(144, 144, 144, 0.5);
    border-radius: 6px;
    padding: 8px 12px;
    margin-left: 12px;
    cursor: pointer;
}

.home_leads_inp_sec>input {
    border: none;
    outline: none;
    padding-left: 12px;
    width: 100%;
}

.home_leads_inp_sec>div{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: rgba(144, 144, 144, 0.7);
    padding-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home_leads_addField {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0084D9;
    border-radius: 6px;
    margin: 12px 12px 12px 0;
    height: 40px;
    padding: 0 12px;
    cursor: pointer;
}

.home_leads_addField>p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    padding-left: 12px;
}

.home_leads_addField>img {
    height: 25px;
    width: 25px;
}

.home_last_row {
    display: flex;
    margin-top: 21px;
    width: 50%;
    justify-content: space-between;
}


.share_first {
    display: flex;
    justify-content: space-around;
    width: 60px;
   
}

.share_first>img {
    display: flex;
    width: 18px;
    object-fit: contain;
    margin-left: 10px;
   
}

.paginate_last {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: 1012px;
}

.paginate_last>div {
    display: flex;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: rgba(10, 78, 123, 0.55);
    width: 68px;
}

.paginate_last>img {
    /* width: 5px; */
    padding: 6px;
    /* object-fit: contain; */
    /* background: #FFFFFF; */
    border: 2px solid #909090;
    border-radius: 3px;
    margin-left: 12px;
}
.select_line{
    object-fit: contain;
    width: 60px !important;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    padding: 0 !important;
    position: relative !important;
}
/* .pop_body:{
    overflow-y: scroll;
} */
.pop_body>input{
    width: 100%;
    height: 30px;
    margin: 8px 0;
    border: 1px solid lightgray;
    outline: none;
    border-radius: 8px;
    padding: 6px ;
}