.Logout{
    width: 40px !important;
    margin-top: 400px;
}


.Top{
margin-top: 60px;

}

.Setting_icons{
    width: 60px !important;
    height: 146px !important;
    margin-top: 300px;
}

.active {
    background-color: #ffffff !important;
    border-radius: 16px 0px 0px 16px;
    padding: 6px ;
    width: 60px !important;
  
  }