.EachRegisteredSchoolPage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.EachRegisteredSchoolHeader {
    display: flex;
    width: 1200px;
    justify-content: space-around;
}

.EachRegisteredSchoolHeader_one {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33%;
    height: 54px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(61, 137, 186, 0.2);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #466FFF;
    cursor: pointer;
}

.EachRegisteredSchoolHeader_one>img {
    height: 35px;
    object-fit: contain;
    padding: 0 12px;
}

.EachRegisteredSchoolHeader_one_active {

    background: rgba(141, 195, 230, 0.2);
    box-shadow: 0px 4px 4px rgba(61, 137, 186, 0.2);

}

.SchoolReports {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center !important;
    justify-content: center;
}

.SchoolReports_top {
    display: flex;
    justify-content: space-between;
    width: calc(90%);
    padding: 21px 5%;
}

.SchoolReports_top_fir {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 28px;
    /* identical to box height */


    color: #545454;
}

.SchoolReports_top_sec {}

.SchoolReports_top_sec>span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    color: #909090;
}

.SchoolReports_top_sec>input {
    width: 180px;
    height: 40px;
    left: 844px;
    top: 95px;
    background: #FFFFFF;
    border: 1px solid #464343;
    border-radius: 10px;
}

.SchoolReports_body {
    width: 100%;
}

.SchoolReports_body_1 {
    width: 1200px;
    height: 310px;
    left: 57px;
    top: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 4px 0px 9px 2px rgba(0, 0, 0, 0.25);
}

.SchoolReports_topfour {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.SchoolReports_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.SchoolReports_box1 {
    background: #811992;
    width: 230px;
    height: 70px;

}

.SchoolReports_box2 {
    background: #2B7312;
    width: 230px;
    height: 70px;
}

.SchoolReports_box3 {
    background: #247BB3;
    width: 230px;
    height: 70px;
}

.SchoolReports_box4 {
    background: #466FFF;
    width: 230px;
    height: 70px;
}

.SchoolReports_num {
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    /* line-height: 42px; */
    height: 50%;
    color: #FFFFFF;
}

.SchoolReports_text {
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    /* line-height: 14px; */
    text-align: center;
    height: 30%;
    color: #FFFFFF;
}

.SchoolReports_body_2 {}

.SchoolReports_body_chart {
    display: flex;
    /* padding: 12px; */
    width: 100%;
    /* align-items: center; */
    padding: 21px 0 0 0;
    justify-content: center;
}

.SchoolReports_body_2Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.SchoolReports_body_2body {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 1200px;
    height: 230px;
    overflow-y: scroll;
    margin-bottom: 21px;
    background: #FFFFFF;
    box-shadow: 2px 4px 7px 3px rgba(0, 0, 0, 0.25);
}